import axios from '@/axios-client.js';
import store from '../store';

const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;

const featureAPI = {
  async getFeaturesBbox(project_slug, queryParams) {
    const response = await axios.get(
      `${baseUrl}projects/${project_slug}/feature-bbox/${queryParams ? '?' + queryParams : ''}`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      const bbox = response.data;
      return [
        [bbox.minLat, bbox.minLon],
        [bbox.maxLat, bbox.maxLon],
      ];
    } else {
      return null;
    }
  },

  async getPaginatedFeatures(url) {
    const response = await axios.get(url);
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getFeatureEvents(featureId) {
    const response = await axios.get(
      `${baseUrl}features/${featureId}/events/`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getFeatureAttachments(featureId) {
    const response = await axios.get(
      `${baseUrl}features/${featureId}/attachments/`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async updateFeature({ feature_id, feature_type__slug, project__slug, newStatus }) {
    let url = `${baseUrl}features/${feature_id}/?feature_type__slug=${feature_type__slug}&project__slug=${project__slug}`;

    const response = await axios({
      url,
      method: 'PATCH',
      data: { id: feature_id, status: newStatus, feature_type: feature_type__slug }
    });
    if (response.status === 200 && response.data) {
      return response;
    } else {
      return null;
    }
  },

  async postComment({ featureId, comment }) {
    const response = await axios.post(
      `${baseUrl}features/${featureId}/comments/`, { comment }
    );
    if (
      response.status === 201 &&
      response.data
    ) {
      return response;
    } else {
      return null;
    }
  },

  async postCommentAttachment({ featureId, file, fileName, commentId, title }) {
    let formdata = new FormData();
    formdata.append('file', file, fileName);
    formdata.append('title', title);

    const response = await axios.put(
      `${baseUrl}features/${featureId}/comments/${commentId}/upload-file/`, formdata
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response;
    } else {
      return null;
    }
  },

  async getFeatureLinks(featureId) {
    const response = await axios.get(
      `${baseUrl}features/${featureId}/feature-links/`
    );
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },

  async getFeaturesBlob(url) {
    const response = await axios
      .get(url, { responseType: 'blob' });
    if (
      response.status === 200 &&
      response.data
    ) {
      return response.data;
    } else {
      return null;
    }
  },
};

export default featureAPI;
